import React, { Dispatch, SetStateAction, useEffect } from "react";

import { addEventToGTMDataLayer } from "@sellernote/_shared/src/utils/common/gtm";

import MainSectionIntroA from "./SectionIntroA";
import MainSectionIntroB from "./SectionIntroB";

export default function MainSectionIntro({
  abTestType,
  setAbTestType,
}: {
  abTestType: string;
  setAbTestType: Dispatch<SetStateAction<string>>;
}) {
  useEffect(() => {
    // 이미 값이 있는 경우에는 할당하지 않음. 사용자에게 일관된 경험 제공.
    if (abTestType) return;

    const randomTestType = Math.random() < 0.5 ? "A" : "B";

    setAbTestType(randomTestType);

    // gtm 이벤트도 최초 노출 시에만 발생.
    addEventToGTMDataLayer({
      event: `hero_ab_${randomTestType}`,
    });
  }, [abTestType, setAbTestType]);

  return (
    <>
      {abTestType === "A" && <MainSectionIntroA />}
      {abTestType === "B" && (
        <MainSectionIntroB setAbTestType={setAbTestType} />
      )}
    </>
  );
}
