import { lazy } from "react";
import Image from "next/image";
import { useRecoilValue } from "recoil";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import { LOCAL_STORAGE_KEY_FOR_SHIPDA_HERO_A_B_TEST_KEY } from "@sellernote/_shared/src/constants/common/abTest";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import CommonStyled from "@sellernote/shipda-kr/src/components/landing/index.styles";
import LandingQuickActions from "@sellernote/shipda-kr/src/components/landing/LandingQuickActions";
import useCustomizeFloatingChatButtonBottomPosition from "@sellernote/shipda-kr/src/hooks/common/useCustomizeFloatingChatButtonBottomPosition";

import Layout from "../../containers/Layout";
import Seo from "../../containers/Seo";
import SuspenseWrapper from "../../containers/SuspenseWrapper";

import AEOSection from "./AEOSection";
import CustomerListSection from "./CustomerListSection";
import Registration from "./Registration";
import SectionIntro from "./SectionIntro";
import useLocalStorageByString from "./SectionIntro/useLocalStorageByString";

const SubSectionList = lazy(() => import("./SubSectionList"));
const LandingSectionUseCases = lazy(
  () =>
    import(
      "@sellernote/shipda-kr/src/components/landing/LandingSectionUseCases"
    )
);
const LandingSectionHowWorks = lazy(
  () =>
    import(
      "@sellernote/shipda-kr/src/components/landing/LandingSectionHowWorks"
    )
);
const LandingSectionRequestServiceGuide = lazy(
  () =>
    import(
      "@sellernote/shipda-kr/src/components/landing/LandingSectionRequestServiceGuide"
    )
);

const customTags = (() => {
  const result = [];

  if (process.env.NEXT_PUBLIC_NAVER_SITE_VERIFICATION) {
    result.push({
      name: "naver-site-verification",
      content: process.env.NEXT_PUBLIC_NAVER_SITE_VERIFICATION,
    });
  }

  if (process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION) {
    result.push({
      name: "google-site-verification",
      content: process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION,
    });
  }

  return result;
})();

export default function Main() {
  const [abTestType, setAbTestType] = useLocalStorageByString(
    LOCAL_STORAGE_KEY_FOR_SHIPDA_HERO_A_B_TEST_KEY,
    ""
  );

  const { isMobile } = useCheckIsMobile();

  const loggedIn = useRecoilValue(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  // Mobile 회원가입 버튼과 채널톡 버튼이 겹치지 않도록 위치 조정.
  useCustomizeFloatingChatButtonBottomPosition({
    position: loggedIn ? 50 : 82,
    disabled: !isMobile,
  });

  return (
    <Layout size="full" isTransparentHeader={true}>
      <Seo
        path="/"
        title={"쉽다 - 디지털 수출입 물류 포워딩 서비스"}
        description={
          "디지털 포워딩 서비스 - 쉽다(ShipDa). 수출입운송부터 국내4PL 풀필먼트까지, 디지털 통합운송 솔루션"
        }
        customTags={customTags}
      />

      <SuspenseWrapper fallback={<Loading active Image={Image} />}>
        <CommonStyled.container>
          <SectionIntro abTestType={abTestType} setAbTestType={setAbTestType} />

          <CustomerListSection />

          <AEOSection />

          <SubSectionList />

          {/* TODO: 아직 서비스 이전인 링크가 많아 이번 배포에서는 숨기기로 함. */}
          {/* <AdditionalServiceSection /> */}

          <LandingSectionUseCases backgroundColor={COLOR.wh} />

          <LandingSectionHowWorks
            sectionBackgroundColor={COLOR.bgColor_1}
            interviewBackgroundColor={COLOR.wh}
          />

          <LandingSectionRequestServiceGuide uiType="section" />

          <LandingQuickActions />

          <Registration abTestType={abTestType} />
        </CommonStyled.container>
      </SuspenseWrapper>
    </Layout>
  );
}
