import { FreightType } from "../../types/common/common";
import { BidConsolidationSellerItemDetails } from "../../types/forwarding/bid";

import { calculateRTon, toFixedFloat } from "../common/number";

/**
 * 창고를 고려한 RTon을 계산
 * (ex. 이우는 500kg당 1RTon, 위해 외의 창고는 250kg이 1RTon으로 계산되는 등 창고마다 계산법이 다름)
 *
 * @param totalCbm - 총 CBM (Cubic Meter)
 * @param totalWeightAsTon - 총 중량 (톤 단위)
 * @param warehouseNameKR - 창고 이름 (국문). (ex. "이우")
 * @returns 계산된 RTon 값
 *
 * @remarks
 * 이우, 위해 외의 창고에는 250kg당 1RTon으로 계산됨
 *
 * TODO: 쉽다 Admin에서는 _shared > calculateRTonForTradingStatement를 사용하고있어 통합 필요
 */
function calculateRTonByWarehouse({
  totalCbm,
  totalWeightAsTon,
  warehouseNameKR,
}: {
  totalCbm: number;
  totalWeightAsTon: number;
  /**
   * 창고이름(국문). (ex. "이우")
   */
  warehouseNameKR: string;
}) {
  /**
   * R.Ton 계산을 위해 창고별로 곱해야 하는 숫자(가중치)
   * 1CBM : 위해는 1000kg, 이우는 500kg, 외의 창고는 250kg
   *
   * TODO: warehouseNameKR이 아닌 창고를 구분하는 고유 프로퍼티로 구분
   */
  const weightByWarehouse = (() => {
    if (warehouseNameKR === "위해") return 1;

    if (warehouseNameKR === "이우") return 2;

    return 4;
  })();

  return calculateRTon(totalCbm, totalWeightAsTon * weightByWarehouse);
}

/**
 * Consolidation CBM을 계산
 *
 * @param detail - BidConsolidationSellerItemDetails 객체
 * @returns 계산된 CBM 값
 *
 * @remarks
 * 높이, 세로, 가로 값이 cm 단위일 경우와 m 단위일 경우를 구분하여 계산
 */
const calculateConsolidationCbm = (
  detail: BidConsolidationSellerItemDetails
) => {
  if (detail.height && detail.vertical && detail.horizontal) {
    if (detail.sizeUnit === "cm") {
      const totalCBM =
        (detail.height / 100) *
        (detail.vertical / 100) *
        (detail.horizontal / 100);

      return toFixedFloat(totalCBM * detail.quantity, 2);
    }

    if (detail.sizeUnit === "m") {
      const totalCBM = detail.height * detail.vertical * detail.horizontal;
      return toFixedFloat(totalCBM * detail.quantity, 2);
    }
  }
  if (detail.cbm) {
    return toFixedFloat(detail.cbm * detail.quantity, 2);
  }

  return 0;
};

/**
 * 특송만 가능한 구간인지 여부를 체크
 *
 * @param polCode - 출발 항구 코드
 * @param podCode - 도착 항구 코드
 * @param freightType - 화물 유형
 * @returns 특송만 가능한 구간인지 여부 (true 또는 false)
 *
 * @remarks
 * 위해항에서 인천항으로 가는 LCL 화물인 경우에만 true를 반환
 */
function checkIsConsolidationOnlyRoute({
  polCode,
  podCode,
  freightType,
}: {
  polCode: string;
  podCode: string;
  freightType: FreightType;
}) {
  // 위해항 -> 인천항 (LCL)인 경우
  return polCode === "CNWEI" && podCode === "KRINC" && freightType === "LCL";
}

export {
  checkIsConsolidationOnlyRoute,
  calculateConsolidationCbm,
  calculateRTonByWarehouse,
};
